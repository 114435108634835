import * as React from "react";

const BlockNine = ({data}) => {
    return (
        <div className="row-fluid-wrapper row-depth-1 row-number-59 ">
            <div className="row-fluid ">
                <div className="span12 widget-span widget-type-cell full-width" data-widget-type="cell" data-x="0" data-w="12">

                    <div className="row-fluid-wrapper row-depth-1 row-number-60">
                        <div className="row-fluid ">
                            <div className="span11 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="11">
                                <div id="hs_cos_wrapper_module_1575658521673208" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_1575658521673208_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text">
                                    <div dangerouslySetInnerHTML={{__html: data.references}}></div>
                                    </span></div>

                            </div>
                            <div className="span1 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="11" data-w="1">
                                <div id="hs_cos_wrapper_module_1575658544089214" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default BlockNine;