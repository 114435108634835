import * as React from "react";


const BlockOne = ({data}) => {
    return (
        <div>
            <div className="row-fluid-wrapper row-depth-1 row-number-7 ">
                <div className="row-fluid ">
                    <div className="span12 widget-span widget-type-custom_widget hidden-phone" style={{ height: "60px" }} data-widget-type="custom_widget" data-x="0" data-w="12">
                        <div id="hs_cos_wrapper_module_155604335471097" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                    </div>
                </div>
            </div>
            <div className="row-fluid-wrapper row-depth-1 row-number-8 ">
                <div className="row-fluid ">
                    <div className="span12 widget-span widget-type-cell full-width sp_3_col" data-widget-type="cell" data-x="0" data-w="12">

                        <div className="row-fluid-wrapper row-depth-1 row-number-9 ">
                            <div className="row-fluid ">
                                <div className="span12 widget-span widget-type-custom_widget visible-desktop" style={{ height: "40px" }} data-widget-type="custom_widget" data-x="0" data-w="12">
                                    <div id="hs_cos_wrapper_module_1556043477650112" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                </div>
                            </div>
                        </div>

                        <div className="row-fluid-wrapper row-depth-1 row-number-10 ">
                            <div className="row-fluid ">
                                <div className="span12 widget-span widget-type-cell " data-widget-type="cell" data-x="0" data-w="12">

                                    <div className="row-fluid-wrapper row-depth-1 row-number-11 ">
                                        <div className="row-fluid ">
                                            <div className="span12 widget-span widget-type-cell " data-widget-type="cell" data-x="0" data-w="12">

                                                <div className="row-fluid-wrapper row-depth-1 row-number-12 ">
                                                    <div className="row-fluid ">
                                                        <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                                            <div id="hs_cos_wrapper_module_1556043641778136" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_1556043641778136_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><h2 className="headlines" style={{ textAlign: "center" }}><span style={{ color: "#c33c33" }} dangerouslySetInnerHTML={{__html: data.tagline}}></span></h2>
                                                                <div dangerouslySetInnerHTML={{__html: data.content}}></div>
                                                                <p style={{ textAlign: "center" }}>&nbsp;</p>
                                                                <h3><strong dangerouslySetInnerHTML={{__html: data.ctaTagline}}></strong></h3>
                                                                <h3><span className="hs-cta-wrapper" id="hs-cta-wrapper-5730af32-f616-4c54-be35-9018aac9716e"><span className="hs-cta-node hs-cta-5730af32-f616-4c54-be35-9018aac9716e" id="hs-cta-5730af32-f616-4c54-be35-9018aac9716e" style={{ visibility: "visible" }} data-hs-drop="true"><a id="cta_button_2642996_7fc1dd65-60ad-407e-a5ab-42f63fed513c" className="cta_button " href={data.ctaUrl} target="_blank" rel="noopener"><span style={{ fontSize: "14px" }} dangerouslySetInnerHTML={{__html: data.ctaLabel}}></span></a></span></span></h3></span></div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlockOne;