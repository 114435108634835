import * as React from "react";

const BlockEight = ({data}) => {
    return (
        <div>
            <div className="row-fluid-wrapper row-depth-1 row-number-50 ">
                <div className="row-fluid ">
                    <div className="span12 widget-span widget-type-custom_widget " style={{ height: "60px" }} data-widget-type="custom_widget" data-x="0" data-w="12">
                        <div id="hs_cos_wrapper_module_1567543704273367" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                    </div>
                </div>
            </div>

            <div className="row-fluid-wrapper row-depth-1 row-number-51 ">
                <div className="row-fluid ">
                    <div className="span12 widget-span widget-type-cell full-width" data-widget-type="cell" data-x="0" data-w="12">

                        <div className="row-fluid-wrapper row-depth-1 row-number-52 ">
                            <div className="row-fluid ">
                                <div className="span12 widget-span widget-type-custom_widget hidden-phone" style={{ height: "60px" }} data-widget-type="custom_widget" data-x="0" data-w="12">
                                    <div id="hs_cos_wrapper_module_1533577831403152" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                </div>
                            </div>
                        </div>

                        <div className="row-fluid-wrapper row-depth-1 row-number-53 ">
                            <div className="row-fluid ">
                                <div className="span12 widget-span widget-type-custom_widget visible-phone" data-widget-type="custom_widget" data-x="0" data-w="12">
                                    <div id="hs_cos_wrapper_module_153358687840896" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                </div>
                            </div>
                        </div>

                        <div className="row-fluid-wrapper row-depth-1 row-number-54 ">
                            <div className="row-fluid ">
                                <div className="span1 widget-span widget-type-cell " data-widget-type="cell" data-x="0" data-w="1">

                                </div>
                                <div className="span10 widget-span widget-type-cell " data-widget-type="cell" data-x="1" data-w="10">

                                    <div className="row-fluid-wrapper row-depth-1 row-number-55 ">
                                        <div className="row-fluid ">
                                            <div className="span12 widget-span widget-type-custom_widget " style={{ textAlign: "center" }} data-widget-type="custom_widget" data-x="0" data-w="12">
                                                <div id="hs_cos_wrapper_module_1533578821303153" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_1533578821303153_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><h2 id="faq" className="headlines" style={{ textAlign: "center" }} dangerouslySetInnerHTML={{__html: data.title}}></h2></span></div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row-fluid-wrapper row-depth-1 row-number-56 ">
                                        <div className="row-fluid ">
                                            <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                                <div id="hs_cos_wrapper_module_1533578318278229" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" data-hs-cos-general-type="widget" data-hs-cos-type="module">
                                                    <button className="PhyAccordion"><div dangerouslySetInnerHTML={{__html: data.question1.question}}></div></button>
                                                    <div className="PhyPanel" dangerouslySetInnerHTML={{__html: data.question1.answer}}>
                                                        
                                                    </div>

                                                    <button className="PhyAccordion"><div dangerouslySetInnerHTML={{__html: data.question2.question}}></div></button>
                                                    <div className="PhyPanel" dangerouslySetInnerHTML={{__html: data.question2.answer}}>
                                                        
                                                    </div>

                                                    <button className="PhyAccordion"><div dangerouslySetInnerHTML={{__html: data.question3.question}}></div></button>
                                                    <div className="PhyPanel" dangerouslySetInnerHTML={{__html: data.question3.answer}}>
                                                        
                                                    </div>

                                                    <button className="PhyAccordion"><div dangerouslySetInnerHTML={{__html: data.question4.question}}></div></button>
                                                    <div className="PhyPanel" dangerouslySetInnerHTML={{__html: data.question4.answer}}>
                                                        
                                                    </div>


                                                    <button className="PhyAccordion"><div dangerouslySetInnerHTML={{__html: data.question6.question}}></div></button>
                                                    <div className="PhyPanel" dangerouslySetInnerHTML={{__html: data.question6.answer}}>
                                                        
                                                    </div>

                                                    <button className="PhyAccordion"><div dangerouslySetInnerHTML={{__html: data.question7.question}}></div></button>
                                                    <div className="PhyPanel" dangerouslySetInnerHTML={{__html: data.question7.answer}}>
                                                        
                                                    </div>


                                                    <button className="PhyAccordion"><div dangerouslySetInnerHTML={{__html: data.question8.question}}></div></button>
                                                    <div className="PhyPanel" dangerouslySetInnerHTML={{__html: data.question8.answer}}>
                                                        
                                                    </div>


                                                    <button className="PhyAccordion"><div dangerouslySetInnerHTML={{__html: data.question9.question}}></div></button>
                                                    <div className="PhyPanel" dangerouslySetInnerHTML={{__html: data.question9.answer}}>
                                                        
                                                    </div>

                                                    <button className="PhyAccordion"><div dangerouslySetInnerHTML={{__html: data.question10.question}}></div></button>
                                                    <div className="PhyPanel" dangerouslySetInnerHTML={{__html: data.question10.answer}}>
                                                        
                                                    </div>

                                                    <button className="PhyAccordion"><div dangerouslySetInnerHTML={{__html: data.question11.question}}></div></button>
                                                    <div className="PhyPanel" dangerouslySetInnerHTML={{__html: data.question11.answer}}>
                                                        
                                                    </div>

                                                    <button className="PhyAccordion"><div dangerouslySetInnerHTML={{__html: data.question12.question}}></div></button>
                                                    <div className="PhyPanel" dangerouslySetInnerHTML={{__html: data.question12.answer}}>
                                                        
                                                    </div>

                                                    <button className="PhyAccordion"><div dangerouslySetInnerHTML={{__html: data.question10.question}}></div></button>
                                                    <div className="PhyPanel" dangerouslySetInnerHTML={{__html: data.question13.answer}}>
                                                        
                                                    </div>

                                                    <button className="PhyAccordion"><div dangerouslySetInnerHTML={{__html: data.question14.question}}></div></button>
                                                    <div className="PhyPanel" dangerouslySetInnerHTML={{__html: data.question14.answer}}>
                                                        
                                                    </div>

                                                    <button className="PhyAccordion"><div dangerouslySetInnerHTML={{__html: data.question15.question}}></div></button>
                                                    <div className="PhyPanel" dangerouslySetInnerHTML={{__html: data.question15.answer}}>
                                                        
                                                    </div>

                                                    <button className="PhyAccordion"><div dangerouslySetInnerHTML={{__html: data.question16.question}}></div></button>
                                                    <div className="PhyPanel" dangerouslySetInnerHTML={{__html: data.question16.answer}}>
                                                        
                                                    </div>

                                                    <button className="PhyAccordion"><div dangerouslySetInnerHTML={{__html: data.question17.question}}></div></button>
                                                    <div className="PhyPanel" dangerouslySetInnerHTML={{__html: data.question17.answer}}>
                                                        
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="span1 widget-span widget-type-cell " data-widget-type="cell" data-x="11" data-w="1">

                                </div>
                            </div>
                        </div>

                        <div className="row-fluid-wrapper row-depth-1 row-number-57 ">
                            <div className="row-fluid ">
                                <div className="span12 widget-span widget-type-custom_widget visible-phone" data-widget-type="custom_widget" data-x="0" data-w="12">
                                    <div id="hs_cos_wrapper_module_153358688043398" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                </div>
                            </div>
                        </div>

                        <div className="row-fluid-wrapper row-depth-1 row-number-58 ">
                            <div className="row-fluid ">
                                <div className="span12 widget-span widget-type-custom_widget hidden-phone" style={{ height: "70px" }} data-widget-type="custom_widget" data-x="0" data-w="12">
                                    <div id="hs_cos_wrapper_module_1533577834742154" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default BlockEight;