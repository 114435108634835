import * as React from "react";

const BlockSeven = ({data}) => {
    return (
        <div className="row-fluid-wrapper row-depth-1 row-number-44 ">
            <div className="row-fluid ">
                <div className="span12 widget-span widget-type-cell full-width sp_3_col" data-widget-type="cell" data-x="0" data-w="12">

                    <div className="row-fluid-wrapper row-depth-1 row-number-45 ">
                        <div className="row-fluid ">
                            <div className="span12 widget-span widget-type-custom_widget visible-phone" data-widget-type="custom_widget" data-x="0" data-w="12">
                                <div id="hs_cos_wrapper_module_1576529742274901" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                            </div>
                        </div>
                    </div>

                    <div className="row-fluid-wrapper row-depth-1 row-number-46 ">
                        <div className="row-fluid ">
                            <div className="span12 widget-span widget-type-custom_widget visible-desktop" style={{ height: "40px" }} data-widget-type="custom_widget" data-x="0" data-w="12">
                                <div id="hs_cos_wrapper_module_1576529742274902" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                            </div>
                        </div>
                    </div>

                    <div className="row-fluid-wrapper row-depth-1 row-number-47 ">
                        <div className="row-fluid ">
                            <div className="span12 widget-span widget-type-cell " data-widget-type="cell" data-x="0" data-w="12">

                                <div className="row-fluid-wrapper row-depth-1 row-number-48 ">
                                    <div className="row-fluid ">
                                        <div className="span12 widget-span widget-type-cell " data-widget-type="cell" data-x="0" data-w="12">

                                            <div className="row-fluid-wrapper row-depth-1 row-number-49 ">
                                                <div className="row-fluid ">
                                                    <div className="span12 widget-span widget-type-custom_widget " style={{ padding: "2% 5%" }} data-widget-type="custom_widget" data-x="0" data-w="12">
                                                        <div id="hs_cos_wrapper_module_1576529742274905" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_1576529742274905_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><h2 className="headlines" style={{ textAlign: "center" }}>&nbsp;</h2>
                                                            <div dangerouslySetInnerHTML={{__html: data.content}} />
                                                            </span></div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default BlockSeven;