import { useStaticQuery, graphql } from "gatsby"

export const usePhysicianQuery = () => {


    const data = useStaticQuery(graphql`
    query PhysicianQuery {
        wpPage(title: {eq: "PHYSICIAN"}) {
          ACF_PhysiciansPage {
            physicianHero {
                image {
                    publicUrl
                }
                text
                tagline
            }

            physicianBlock1{
                tagline
                content
                ctaTagline
                ctaUrl
                ctaLabel
            }

            physicianBlock2{
                image {
                    publicUrl
                }
                content
            }

            physicianBlock3{
                image {
                    publicUrl
                }
                content
            }

            physicianBlock4{
                image {
                    publicUrl
                }
                content
            }

            physicianBlock5{
                content
            }

            physicianBlock6{
                content
            }

            physicianFaqs {
                title
                question1{
                    question
                    answer
                }
                question2{
                    question
                    answer
                }
                question3{
                    question
                    answer
                }
                question4{
                    question
                    answer
                }
                question6{
                    question
                    answer
                }
                question7{
                    question
                    answer
                }
                question8{
                    question
                    answer
                }
                question9{
                    question
                    answer
                }
                question10{
                    question
                    answer
                }
                question11{
                    question
                    answer
                }
                question12{
                    question
                    answer
                }
                question13{
                    question
                    answer
                }
                question14{
                    question
                    answer
                }
                question15{
                    question
                    answer
                }
                question16{
                    question
                    answer
                }
                question17{
                    question
                    answer
                }
                references
            }

            physicianMetaData {
                metaTitle
                metaDescription
            }
        }
        }
    }     
    `)
    return data.wpPage.ACF_PhysiciansPage;
}