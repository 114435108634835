import * as React from "react";
import Layout from "../components/global/Layout";
import Hero from "../components/physician/Hero";
import BlockOne from "../components/physician/BlockOne";
import BlockTwo from "../components/physician/BlockTwo";
import BlockThree from "../components/physician/BlockThree";
import BlockFour from "../components/physician/BlockFour";
import BlockFive from "../components/physician/BlockFive";
import BlockSeven from "../components/physician/BlockSeven";
import BlockEight from "../components/physician/BlockEight";
import BlockNine from "../components/physician/BlockNine";
import { usePhysicianQuery } from "../hooks/usePhysicianQuery";
import Helmet from "react-helmet";

const PhysicianPage = () => {
    const data = usePhysicianQuery();
    return (
        <Layout>
            <Hero data={data.physicianHero} />
            <BlockOne data={data.physicianBlock1} />
            <BlockTwo data={data.physicianBlock2} />
            <BlockThree data={data.physicianBlock3} />
            <BlockFour data={data.physicianBlock4} />
            <BlockFive data={data.physicianBlock5} />
            <BlockSeven data={data.physicianBlock6}/>
            <BlockEight data={data.physicianFaqs} />
            <BlockNine data={data.physicianFaqs} />
            <Helmet>
                <title>{data.physicianMetaData.metaTitle}</title>
                <meta name="description" content={data.physicianMetaData.metaDescription} />
             </Helmet>
        </Layout>
    )
}

export default PhysicianPage;