import * as React from "react";

const BlockThree = ({data}) => {
    return (
        <div>
            <div className="row-fluid-wrapper row-depth-1 row-number-17 ">
                <div className="row-fluid ">
                    <div className="span12 widget-span widget-type-custom_widget " style={{ height: "60px" }} data-widget-type="custom_widget" data-x="0" data-w="12">
                        <div id="hs_cos_wrapper_module_1555303742406827" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                    </div>
                </div>
            </div>


            <div className="row-fluid-wrapper row-depth-1 row-number-18 ">
                <div className="row-fluid ">
                    <div className="span12 widget-span widget-type-cell equal-heights full-width sp_box_shadow" data-widget-type="cell" data-x="0" data-w="12">

                        <div className="row-fluid-wrapper row-depth-1 row-number-19 ">
                            <div className="row-fluid ">
                                <div className="span6 widget-span widget-type-cell " style={{backgroundImage: "url('"+data.image.publicUrl+"')", backgroundPosition: "left top", backgroundRepeat: "no-repeat", backgroundSize: "cover"}} data-widget-type="cell" data-x="0" data-w="6">

                                </div>
                                <div className="span6 widget-span widget-type-cell " data-widget-type="cell" data-x="6" data-w="6">

                                    <div className="row-fluid-wrapper row-depth-1 row-number-20 ">
                                        <div className="row-fluid ">
                                            <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                                <div id="hs_cos_wrapper_module_1555303686415778" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_1555303686415778_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text">
                                                    <div dangerouslySetInnerHTML={{__html: data.content}}></div>
                                                    </span></div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlockThree;