import * as React from "react";

const BlockFive = ({data}) => {
    return (
        <div className="row-fluid-wrapper row-depth-1 row-number-31 ">
            <div className="row-fluid ">
                <div className="span12 widget-span widget-type-cell full-width sp_3_col" data-widget-type="cell" data-x="0" data-w="12">

                    <div className="row-fluid-wrapper row-depth-1 row-number-32 ">
                        <div className="row-fluid ">
                            <div className="span12 widget-span widget-type-custom_widget visible-phone" data-widget-type="custom_widget" data-x="0" data-w="12">
                                <div id="hs_cos_wrapper_module_1567543876682450" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                            </div>
                        </div>
                    </div>

                    <div className="row-fluid-wrapper row-depth-1 row-number-33 ">
                        <div className="row-fluid ">
                            <div className="span12 widget-span widget-type-custom_widget visible-desktop" style={{height: "40px"}} data-widget-type="custom_widget" data-x="0" data-w="12">
                                <div id="hs_cos_wrapper_module_1555305703213346" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                            </div>
                        </div>
                    </div>

                    <div className="row-fluid-wrapper row-depth-1 row-number-34 ">
                        <div className="row-fluid ">
                            <div className="span12 widget-span widget-type-cell " data-widget-type="cell" data-x="0" data-w="12">

                                <div className="row-fluid-wrapper row-depth-1 row-number-35 ">
                                    <div className="row-fluid ">
                                        <div className="span12 widget-span widget-type-cell " data-widget-type="cell" data-x="0" data-w="12">

                                            <div className="row-fluid-wrapper row-depth-1 row-number-36 ">
                                                <div className="row-fluid ">
                                                    <div className="span12 widget-span widget-type-custom_widget " style={{padding: "2% 5%"}} data-widget-type="custom_widget" data-x="0" data-w="12">
                                                        <div id="hs_cos_wrapper_module_1555305703213353" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_1555305703213353_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text">
                                                            <div dangerouslySetInnerHTML={{__html: data.content}}></div>
                                                            </span></div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default BlockFive;