import * as React from "react";

const BlockFour = ( {data}) => {
    return (
        <div>
            <div className="row-fluid-wrapper row-depth-1 row-number-21 ">
                <div className="row-fluid ">
                    <div className="span12 widget-span widget-type-custom_widget " style={{ height: "60px" }} data-widget-type="custom_widget" data-x="0" data-w="12">
                        <div id="hs_cos_wrapper_module_15553548185221170" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                    </div>
                </div>
            </div>


            <div className="row-fluid-wrapper row-depth-1 row-number-22 ">
                <div className="row-fluid ">
                    <div className="span12 widget-span widget-type-cell full-width sp_home_steps_outer" style={{ backgroundImage: "url('"+data.image.publicUrl+"')", backgroundSize: "cover", backgroundPosition: "center top" }} data-widget-type="cell" data-x="0" data-w="12">

                        <div className="row-fluid-wrapper row-depth-1 row-number-23 ">
                            <div className="row-fluid ">
                                <div className="span6 widget-span widget-type-cell sp_home_steps" style={{ marginLeft: "80px" }} data-widget-type="cell" data-x="0" data-w="6">

                                    <div className="row-fluid-wrapper row-depth-1 row-number-24 ">
                                        <div className="row-fluid ">
                                            <div className="span12 widget-span widget-type-custom_widget hidden-phone" style={{ height: "60px" }} data-widget-type="custom_widget" data-x="0" data-w="12">
                                                <div id="hs_cos_wrapper_module_15553052028981483" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row-fluid-wrapper row-depth-1 row-number-25 ">
                                        <div className="row-fluid ">
                                            <div className="span12 widget-span widget-type-custom_widget visible-phone" data-widget-type="custom_widget" data-x="0" data-w="12">
                                                <div id="hs_cos_wrapper_module_15553052028981484" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row-fluid-wrapper row-depth-1 row-number-26 ">
                                        <div className="row-fluid ">
                                            <div className="span10 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="10">
                                                <div id="hs_cos_wrapper_module_15553052028981485" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_15553052028981485_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text">
                                                    <div dangerouslySetInnerHTML={{__html: data.content}}></div>
                                                    </span></div>

                                            </div>
                                            <div className="span2 widget-span widget-type-custom_widget hidden-phone" style={{ height: "60px" }} data-widget-type="custom_widget" data-x="10" data-w="2">
                                                <div id="hs_cos_wrapper_module_1567543789141431" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row-fluid-wrapper row-depth-1 row-number-27 ">
                                        <div className="row-fluid ">
                                            <div className="span12 widget-span widget-type-custom_widget sp_red_btn" style={{ paddingTop: "2%" }} data-widget-type="custom_widget" data-x="0" data-w="12">
                                                <div id="hs_cos_wrapper_module_15553052028981486" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-cta" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_15553052028981486_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_cta" data-hs-cos-general-type="widget" data-hs-cos-type="cta"></span></div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row-fluid-wrapper row-depth-1 row-number-28 ">
                                        <div className="row-fluid ">
                                            <div className="span12 widget-span widget-type-custom_widget visible-phone" data-widget-type="custom_widget" data-x="0" data-w="12">
                                                <div id="hs_cos_wrapper_module_15553052028981487" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="span6 widget-span widget-type-cell " data-widget-type="cell" data-x="6" data-w="6">

                                </div>
                            </div>
                        </div>

                        <div className="row-fluid-wrapper row-depth-1 row-number-29 ">
                            <div className="row-fluid ">
                                <div className="span12 widget-span widget-type-custom_widget hidden-phone" style={{ height: "100px" }} data-widget-type="custom_widget" data-x="0" data-w="12">
                                    <div id="hs_cos_wrapper_module_15553052028981489" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                </div>
                            </div>
                        </div>

                        <div className="row-fluid-wrapper row-depth-1 row-number-30 ">
                            <div className="row-fluid ">
                                <div className="span12 widget-span widget-type-custom_widget visible-phone" data-widget-type="custom_widget" data-x="0" data-w="12">
                                    <div id="hs_cos_wrapper_module_1555305703213345" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlockFour;